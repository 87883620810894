
import {
    TechRadarApi,
    TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';

export class SfTechRadarClient implements TechRadarApi {
    async load(_id: string | undefined): Promise<TechRadarLoaderResponse> {
        const data = await import('./swftechradar.json', { assert: { type: "json" } });
        return {
            ...data,
            entries: data.entries.map(entry => ({
                ...entry,
                timeline: entry.timeline.map(timeline => ({
                    ...timeline,
                    date: new Date(timeline.date),
                })),
            })),
        };
    }




}
