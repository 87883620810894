import {
    ScmIntegrationsApi,
    scmIntegrationsApiRef,
    ScmAuth,
} from '@backstage/integration-react';
import {
    AnyApiFactory,
    configApiRef,
    createApiFactory,
} from '@backstage/core-plugin-api';
import {techRadarApiRef} from "@backstage/plugin-tech-radar";
import {SfTechRadarClient} from "./lib/sf-tech-radar-client";



export const apis: AnyApiFactory[] = [
    createApiFactory(techRadarApiRef, new SfTechRadarClient()),
    createApiFactory({
        api: scmIntegrationsApiRef,
        deps: {configApi: configApiRef},
        factory: ({configApi}) => ScmIntegrationsApi.fromConfig(configApi),
    }),
    ScmAuth.createDefaultApiFactory(),
];
