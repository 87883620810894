import React from 'react';
import {Content, Header, Page} from "@backstage/core-components";
import {HomePageCompanyLogo, HomePageToolkit, WelcomeTitle} from "@backstage/plugin-home";
import {
    ArtifactoryLogo,
    GitlabLogo,
    HomePageLogo, InnersourceLogo,
    RunnerLogo,
    SoftwareFactoryIndexLogo,
    SonarLogo, SupportLogo,
    SynopsysLogo, TechDocLogo
} from "./HomePageLogo";
import { Grid } from '@material-ui/core';
import {AnnouncementsCard} from "@k-phoen/backstage-plugin-announcements";

export const HomePage = () => (
        <Page themeId="home">
            <Header title={
                <HomePageCompanyLogo
                    logo={<HomePageLogo />}
                />}
                    pageTitleOverride="Home">
            </Header>
            <Content>
                <Grid container item xs={12} justifyContent='center'>
                <h2 style={{textAlign: 'left', width: '100%', color: 'darkblue'}}><WelcomeTitle
                    language={['English', 'French']}/></h2>
                <h4
                    style={{textAlign: 'left', width: '100%', color: 'grey'}}> Here your command center for software
                    factory resources.</h4>
                <Grid item xs={12}>
                    <HomePageToolkit
                        key="home-page-sf-toolkit"
                        title='Toolkit'
                        tools={[
                            {
                                label: "GitLab",
                                url: "https://gitlab.thalesdigital.io/",
                                icon: <GitlabLogo />
                            },
                            {
                                label: "Artifactory",
                                url: "https://artifactory.thalesdigital.io/",
                                icon: <ArtifactoryLogo />
                            },
                            {
                                label: "SonarQube",
                                url: "https://quality-analysis.thalesdigital.io/",
                                icon: <SonarLogo />
                            },
                            {
                                label: "Coverity",
                                url: "https://coverity.thalesdigital.io/",
                                icon: <SynopsysLogo />
                            },
                            {
                                label: "Black Duck",
                                url: "https://blackduck.dev-blackduck.sfaas.core-dev.apps.thalesdigital.io/",
                                icon: <SynopsysLogo />
                            },
                            {
                                label: "Dedicated Runner Windows",
                                url: " ",
                                icon: <RunnerLogo />
                            },
                            {
                                label: "Dedicated Runner Linux",
                                url: " ",
                                icon: <RunnerLogo />
                            },
                        ]}
                    />
                </Grid>
                    <Grid item xs={12} md={6}>
                        <AnnouncementsCard key="home-page-announcements" max={3} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <HomePageToolkit
                            key="home-page-sf-help-support"
                            title='Help & Support'
                            tools={[
                                {
                                    label: "Software factory index",
                                    url: "https://thalesgroup.sharepoint.com/sites/TDF-WW-TrustNestDigitalPlatform/SitePages/SWF%20-%20Software-factory-home.aspx",
                                    icon: <SoftwareFactoryIndexLogo />
                                },
                                {
                                    label: "Technical documentation",
                                    url: "https://docs.thalesdigital.io/software-factory/",
                                    icon: <TechDocLogo />
                                },
                                {
                                    label: "Innersource community",
                                    url: "https://thalesgroup.sharepoint.com/sites/inner-source-community",
                                    icon: <InnersourceLogo />
                                },
                                {
                                    label: "Support",
                                    url: "https://postit.service-now.com/esc",
                                    icon: <SupportLogo />
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            </Content>

        </Page>
);